<template>
    <div class="behaviorBar"></div>
</template>

<script>
export default {
    name: '',
    props: {
        data: Array
    },
    data() {
        return {
          moneyList:[]
        }
    },
    methods: {
        setChart() {
            let option = {
                title: {
                    text: '【投入款物（万元）】',
                    textStyle: {
                        color: '#75deef',
                        fontSize: 12,
                        fontWeight: 'normal'
                    },
                    top: '5%',
                    left: '35%'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'none'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    backgroundColor: '#11367a',
                    // formatter: "{b}:{c1}",
                    textStyle: {
                        color: '#6dd0e3',
                        fontSize: 10,
                    },
                  formatter: function (params) {
                    let str = params[0].name;
                    params.forEach(item=> {
                      str += `<br> ${item.seriesName}: ${item.value}`
                    })
                    return str
                  }
                },
                grid:
                    {
                        top: '25%',
                        left: '1%',
                        right: '4%',
                        bottom: '4%',
                        containLabel: true,

                    },
                xAxis:
                    {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            color: '#75deef',
                            fontSize: 9,
                            showMinLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#1a3c58',
                                width: 2
                            }
                        },
                        axisTick: {show: false},

                      data: ["长沙市",'株洲市','湘潭市','衡阳市',"常德市",'益阳市','娄底市','郴州市',"永州市",'怀化市','湘西'],
                    },
                yAxis: [
                    {
                        type: 'value',
                        min: 0,
                        max: 100,
                        axisLabel: {
                            color: '#75deef',
                            fontSize: 9,
                            showMaxLabel: false,
                        },
                        name: '万元',
                        nameGap: -5,
                        nameTextStyle: {
                            color: '#75deef',
                            fontSize: 9,
                            align: 'right',
                            padding: [0, 6, 0, 0]
                        },
                        axisTick: {
                            length: 3
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#1a3c58'
                            }
                        },

                        splitLine: {show: false}

                    },
                ],
                series: [
                    // {
                    //     name: '辅助',
                    //     type: 'bar',
                    //     barWidth: 12,
                    //     itemStyle: {
                    //         color: "#252448",
                    //     },
                    //     data: [300, 300, 300, 300, 300, 300, 300, 300]
                    // },
                    // {
                    //     name: '添加好友',
                    //     type: 'bar',
                    //     barWidth: 12,
                    //     barGap: '-100%',
                    //
                    //     data: this.data
                    // },
                  {
                    name: '投入款物(万元)',
                    type: 'bar',
                    itemStyle: {
                      color:{
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                          offset: 0, color: 'rgba(241,156,179,0.8)' // 0% 处的颜色
                        },
                          {
                            offset: 0.4, color: '#da63ac' // 40% 处的颜色
                          }, {
                            offset: 1, color: '#8c1e83' // 100% 处的颜色
                          }],
                        global: false // 缺省为 false
                      } //背景渐变色
                    },
                    barWidth: 10,
                    barCategoryGap: 10,
                    data: this.moneyList
                  },
                ]
            };

            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        }
    },
    mounted() {
        this.setChart()
    }

}
</script>

<style lang="less" scoped>
.behaviorBar {
    height: 45%;
}
</style>